.funds {
  justify-content: center;
}

.funds-intro {
  display: flex;
  margin-bottom: 45px;
  align-items: center;
  width: 98%;
  /* margin-left: 10px; */
}

.funds-body {
  display: flex;
  flex-wrap: wrap;
  /* margin-left: 20px; */
}

.funds-body > img {
  margin-right: 20px;
  width: 200px;
  height: 200px;
  /* margin-left: 10px; */
}

.funds-body-text > h1 {
  font-size: 20px;
  line-height: 1.1;
  margin-bottom: 10px;
}

.funds-body-text > p {
  max-width: 1000px;
}

.funds-body-text > ul {
  padding-left: 50px;
  padding-bottom: 20px;
  padding-top: 10px;
  line-height: 2;
}

@media screen and (max-width: 760px) {
  .funds {
    margin-left: 10px;
  }
}
