* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: "Segoe UI", "Helvetica Neue", , Arial, sans-serif; */
  font-family: "Segoe UI", Helvetica, "Helvetica Neue", Oxygen, Ubuntu,
    Cantarell, Roboto, "Open Sans", sans-serif;
  color: #113053;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: linear-gradient(90deg, rgb(247, 244, 244), white);
}

.app-content-wrap {
  flex: 1;
}
