.products {
  padding: 0;
  justify-content: center;
}

.products-intro {
  display: flex;
  margin-bottom: 45px;
  align-items: center;
  width: 98%;
  margin-left: 10px;
}
