.pageintro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  box-shadow: gray 0 0 2px;
  background-color: rgba(236, 232, 232, 0.966);
  padding: 20px;
}

.pageintro > h1 {
  font-size: 20px;
}

.pageintro > p {
  font-size: 15px;
  margin-top: 10px;
}
