.contacts {
  margin-left: 50px;
}

.contacts-row {
  display: flex;
  justify-content: space-between;
  margin-right: 50px;
}

.contacts-map {
  display: flex;
  margin: 20px 220px 20px 20px;
  width: 400px;
  height: 270px;
  border: 1px solid white;
  border-radius: 5px;
}

@media screen and (max-width: 760px) {
  .contacts {
    margin-left: 10px;
  }

  .contacts-row {
    flex-direction: column;
  }

  .contacts-map {
    display: flex;
    margin: 20px 220px 20px 20px;
    width: 200px;
    height: 120px;
    border: 1px solid white;
    border-radius: 5px;
  }
}
