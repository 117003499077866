.service {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
}

.service > a > img {
  display: flex;
  width: 80px;
  height: 80px;
  margin-right: 15px;
  z-index: -1;
}

.service-text {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 500px;
  margin-right: 50px;
}

.service-text > p {
  font-size: 14px;
}

.service-text > a {
  font-size: 14px;
  line-height: 1.1;
  margin-bottom: 10px;
  text-decoration: none;
  font-weight: bold;
}

@media screen and (max-width: 760px) {
  .service {
    padding-bottom: 20px;
    flex-direction: column;
    max-width: 100%;
    flex-basis: 100%;
  }

  .service > a > img {
    margin-left: 0;
    margin-right: 0;
  }
}
