.header {
  height: 80px;
  display: flex;
  font-size: 15px;
  top: 0;
  z-index: 999;
  justify-content: center;
  align-items: center;
}

.header-banner {
  width: 100%;
  height: 5px;
  background-image: linear-gradient(to right, white, rgb(245, 189, 23), white);
  opacity: 0.7;
}

.header-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: center;
}

.container {
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0;
  padding-left: 0;
}

.header-logo {
  align-items: center;
  cursor: pointer;
}

.header-logo > img {
  width: 200px;
  height: 50px;
  justify-self: flex-start;
  display: flex;
}

.header-menu {
  display: flex;
  list-style: none;
}

.header-item {
  height: 80px;
}

.header-links {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1.5rem;
  height: 100%;
  font-weight: 500;
}

.header-links-active {
  font-weight: bold;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 878px) {
  .header-logo > img {
    width: 150px;
    height: 40px;
  }

  .header-links {
    font-size: 12px;
    padding: 0.5rem 0.8rem;
  }
}

@media screen and (max-width: 680px) {
  .header-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    font-size: 12px;
    transition: all 0.5s ease;
  }

  .header-menu.active {
    background: #1c2237;
    padding-top: 20px;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  .header-links {
    color: #fff;
    text-align: center;
    width: 100%;
    display: table;
    padding-top: 10px;
  }

  .header-links:hover {
    color: #f9c013;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .header-item:hover {
    border: none;
  }

  .header-item {
    width: 100%;
  }

  .header-logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    justify-self: start;
    justify-items: center;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 10px;
    transform: translate(-100%, 60%);
    font-size: 1.6rem;
    cursor: pointer;
  }
}
