.product {
  align-items: center;
}

.product-row {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.product-body {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  padding-top: 20px;
}

.product-body > a {
  text-decoration: none;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

a:hover {
  color: #f9c013;
}

.product-body > p {
  max-width: 450px;
  padding-bottom: 10px;
}

.product-image-wrapper {
  margin: 10px 20px 30px 20px;
}

.product-image {
  border-radius: 50%;
  box-shadow: gray 0 0 10px;
  border: 1px solid white;
  max-width: 250px;
}
