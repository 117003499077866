.services {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  align-items: center;
  z-index: 0;
  position: relative;
}

.services-intro {
  display: flex;
  margin-bottom: 45px;
  align-items: center;
  width: 98%;
  /* margin-left: 10px; */
}

.services-details {
  display: flex;
  flex-direction: row;
  /* margin-left: 10px; */
}

.services-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 760px) {
  .services-details {
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    padding-left: 30px;
  }

  .services-intro {
    margin-left: 10px;
  }
}
