.home-image {
  display: flex;
  background-image: url("/images/cinch-bg-wide-original.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  border-radius: 5px;
  border: 1px solid white;
  box-shadow: gray 0 0 10px;
  align-items: flex-start;
  justify-content: flex-end;
  min-height: 300px;
}

.home-image > span {
  font-family: Helvetica, sans-serif;
  font-size: 20px;
  color: 113053;
  font-weight: 900;
  padding-top: 20px;
  padding-right: 40px;
}

.home-aboutus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

.home-aboutus-container {
  margin-top: 30px;
}

@media screen and (max-width: 878px) {
  .home-aboutus {
    flex-direction: column;
  }

  .home-image > span {
    font-size: medium;
  }
}

@media screen and (max-width: 680px) {
  .home-aboutus {
    flex-direction: column;
  }

  .home-image {
    background-size: cover;
    min-height: 150px;
  }

  .home-image > span {
    font-size: x-small;
  }
}
