.aboutus {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 500px;
}

.aboutus-icon {
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.aboutus-icon > img {
  height: 150px;
  width: 150px;
}

.aboutus-text {
  display: flex;
  flex-direction: column;
}

.aboutus-text > h1 {
  font-size: 30px;
  color: #122f53;
}

.aboutus-text > p {
  font-size: medium;
  margin-top: 5px;
  color: #929496;
}
