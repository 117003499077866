.footer {
  background-color: #e8e8e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 0;
  width: 100%;
  min-height: 35px;
}

.footer-copyright {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.footer-copyright > p {
  font-size: 12px;
  font-weight: bolder;
  margin-left: 5px;
  color: #8f9294;
}

.footer-links {
  text-decoration: none;
  font-size: smaller;
  margin-right: 20px;
}

.footer-icons {
  vertical-align: middle;
  margin-right: 5px;
  width: 15px;
  height: 15px;
  color: #113053;
}

.footer-span {
  font-weight: bold;
  font-size: smaller;
  color: #8f9294;
}
