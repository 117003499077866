.contact-row {
  display: flex;
  margin: 40px 150px 40px 20px;
}

.contact-row > img {
  width: 150px;
  height: 150px;
  margin-right: 40px;
}

.contact-text > h1 {
  font-size: 18px;
  max-width: 150px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 760px) {
  .contact-row {
    flex-direction: column;
  }
}

.contact-text {
  max-width: 100%;
  flex-basis: 100%;
}
